.Header {
  margin-top: -10px;
}

.Header-Container {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;
}

.Title {
  font-size: 2rem;
  font-weight: 500;
  color: #4f4f4f;
}

.Subtitle {
  color: rgba(105, 105, 105, 0.68);
  font-weight: 400;
  font-size: 1.35rem;
}

.Title .Subtitle {
  margin-top: -1.6rem;
  font-size: 1rem;
}

.Header::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background-color: rgba(44, 139, 149, 0.23);
}

.select:after {
  border-bottom: 2px solid #298089 !important;
}

.select-container {
  margin-bottom: 15px;
}

.filter-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.filter-row {
  flex-direction: row;
}

.filter-flex-column {
  justify-content: flex-end;
}

.filter-form-label {
  justify-content: flex-start;
  padding: 13px !important;
}

.menu-item {
  position: relative;
  padding-left: 16px;
  padding-right: 6px;
  padding-top: 6px;
}

li .menu-item {
  position: relative;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}

.validator-description {
  color: grey;
  margin: 3px 0 4px 0;
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 85%;
}

li .validator-description {
  white-space: normal;
}

.schema-description {
  color: grey;
  margin: 3px 0 4px 0;
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 85%;
}

li .schema-description {
  white-space: normal;
}
