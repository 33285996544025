.ErrorMessage {
  width: 98%;
  border: 1px solid rgb(237, 237, 237);
  border-radius: 5px;
  color: rgb(77, 77, 77);
  margin-left: 2%;
}

.ErrorMessageHeading {
  margin-left: 20px;
  font-weight: 600;
  margin-bottom: -10px;
}

.ErrorMessageText {
  font-weight: 400;
  padding: 0 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}
