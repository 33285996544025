.FileContainer {
  max-width: 65vw;
}

.FileRow {
  width: 100%;
  height: 75px;
  display: flex;
  align-items: center;
  border: 1px solid rgb(237, 237, 237);
  border-radius: 5px;
  color: rgb(105, 105, 105);
  background-color: #fff;
  overflow: hidden;
}

.FileRow img {
  height: 50%;
  margin: 0 20px;
}

.FileName {
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.FileSize {
  font-size: 0.8rem;
  margin-left: 10px;
  margin-right: auto;
  color: rgba(78, 78, 78, 0.5);
  width: 60px;
  flex-shrink: 0;
}

.FileButtonsContainer {
  width: 200px;
  flex-shrink: 0;
}

.DeleteButton {
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  margin-left: 130px;
  width: 60px;
  flex-shrink: 0;
}

.DeleteButton img {
  width: 20px;
  margin: 0 auto;
}

.DownloadButton {
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
}

.DownloadButton img {
  width: 20px;
  margin: 0 auto;
}

.Dropdown {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-weight: 500;
  flex-shrink: 0;
}

.DropdownButton {
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  padding: 0;
  display: flex;
  align-items: center;
}

.DropdownButtonTitle {
  color: #298089;
  font-weight: 600;
  font-size: 12pt;
  margin-right: -15px;
}

.DropdownButton img {
  height: 2rem;
  fill: #298089;
}

.DropdownRotate img {
  transform: rotate(180deg);
}

.spinner {
  flex-shrink: 0;
  animation: rotate 2s linear infinite;
  z-index: 2;
  width: 50px;
  height: 50px;
  margin-left: 130px;
}
.spinner .path {
  stroke: #298089;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

@import "FileRow.media";
