.Report {
  width: 100%;
  border: 1px solid rgb(237, 237, 237);
  border-radius: 5px;
  color: rgb(77, 77, 77);
  /* padding: 20px; */
}

.ReportTitle {
  text-align: center;
  font-weight: 600;
}

.ReportHeadingTitle {
  text-align: center;
}

.ReportHeadingText {
  font-weight: 400;
}

.ReportSectionSeperator {
  width: 100%;
  height: 1px;
  background-color: rgba(77, 77, 77, 0.2);
  margin: 0 auto;
}

.reportAccordion {
  display: flex;
  flex-direction: row;
  border: 1px solid rgb(0, 0, 0);
}

.reportAccordionHeading {
  display: flex;
  justify-content: flex-start;
  flex: 6;
}

.reportAccordionIcon {
  display: flex;
  justify-content: flex-end;
  flex: 2;
}

.SchemaViolationsSectionHeading {
  background-color: rgba(60, 60, 60, 0.1);
  margin: 0;
  padding: 20px;
  font-weight: 600;
}

.ErrorsSectionHeading {
  background-color: rgba(60, 60, 60, 0.1);
  margin: 0;
  padding: 20px;
  font-weight: 600;
}

.AlertsSectionHeading {
  background-color: rgba(255, 69, 0, 0.1);
  margin: 0;
  padding: 20px;
  font-weight: 600;
}

.FatalErrorsSectionHeading {
  background-color: rgba(60, 60, 60, 0.1);
  margin: 0;
  padding: 20px;
  font-weight: 600;
}

.SevereWarningsSectionHeading {
  background-color: rgba(60, 60, 60, 0.1);
  margin: 0;
  padding: 20px;
  font-weight: 600;
}

.WarningsSectionHeading {
  background-color: rgba(60, 60, 60, 0.1);
  margin: 0;
  padding: 20px;
  font-weight: 600;
}

.InformationalSectionHeading {
  background-color: rgba(50, 205, 50, 0.1);
  margin: 0;
  padding: 20px;
  font-weight: 600;
}

.AffirmativeSectionHeading {
  background-color: rgba(144, 238, 144, 0.1);
  margin: 0;
  padding: 20px;
  font-weight: 600;
}

.SuccessSectionHeading {
  background-color: rgba(144, 238, 144, 0.1);
  margin: 0;
  padding: 20px;
  font-weight: 600;
}

.NotesSectionHeading {
  background-color: rgba(60, 60, 60, 0.1);
  margin: 0;
  padding: 20px;
  font-weight: 600;
}

.RawDataSectionHeading {
  background-color: rgba(165, 42, 42, 0.1);
  margin: 0;
  padding: 20px;
  font-weight: 600;
}

.Report a {
  text-decoration: none;
  color: #00a0af;
  margin: 1em;
  font-weight: bold;
  display: block;
}

.Report a:hover {
  text-decoration: underline;
}
