.ResetButton {
  background: linear-gradient(to top, #298089, #71BBC1);
  height: 55px;
  width: 250px;
  color: white;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2px;
  border: none;
  outline: none;
  border-radius: 6px;
  
  transition: all 1s ease;
}

.ResetButton:hover:enabled {
  background: linear-gradient(to top, #20646A, #71BBC1);
  cursor: pointer;
  
}

.ResetButton:active:enabled {
  background: linear-gradient(to bottom, #298089, #71BBC1);
}

.ResetButton:disabled {
  opacity: 0.5;
}
