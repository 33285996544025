@media (max-width: 1280px) {
  .FileName {
    font-size: .9rem;
  }


}

@media (max-width: 950px) {

  .FileRow {
    padding: 10px 0;
  }

  .FileName {
    font-size: .8rem;
  }

  .DropdownButtonTitle {
    font-size: .8rem;
  }


}

@media (max-width: 870px) {

  .FileRow {
    padding: 20px 0;
  }

  .FileName {
    font-size: .8rem;
  }

  .DropdownButtonTitle {
    font-size: .8rem;
  }


}
