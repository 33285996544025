.Card {
  background-image: linear-gradient(to top right, #298089, #61dfea);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Card-Files {
  height: 100%;
  min-height: 100vh;
}

.Card-Files-X {
  padding: 50px 0;
}

.Card-Container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Card-Shadow {
  height: 50%;
  width: 60vw;
  position: absolute;
  bottom: 0;
  left: 7vw;
  box-shadow: 0 30px 65px 23px rgba(15, 91, 98, 0.58);
}

.Card-Face {
  background-color: #fff;
  width: 65vw;
  border-radius: 15px;
  z-index: 2;
  padding: 40px 80px;
}

.Card-Fixed {
  max-height: 800px;
}

.Card-Footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.Card-Footer img {
  height: 50px;
  margin-top: 30px;
  margin-left: 20px;
}

.Warning-Message {
  font-size: 12px;
  text-align: center;
  margin-top: 20px;
}

.Alert-Message {
  font-size: 20px;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: darkred;
  color: white;
}
