.Dropzone {
  width: 98.8%;
  height: 40%;
  margin-top: 35px;
  border: dashed 5px rgba(49, 139, 148, 0.28);
  border-image: url('./../../images/Dropzone/dashedBorderRound.png') 12 round;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Dropzone-Container {
  display: grid;
  margin: 20px 0;
}

.FilesIcon {
  width: 50px;
  justify-self: center;
  align-self: center;
  margin-top: 20px;
}

.Dropzone-Title {
  color: rgba(194, 194, 194, 0.82);
  font-weight: 400;
  font-size: 1.5rem;
  text-align: center;
}

.Dropzone-Subtitle {
  color: rgba(194, 194, 194, 0.82);
  font-weight: 400;
  font-size: 1rem;
  text-align: center;
  margin-top: -10px;
}

.Highlight {
  background-color: rgba(41, 128, 137, 0.1);
}

.Icon {
  opacity: 0.3;
  height: 64px;
  width: 64px;
}

.FileInput {
  display: none;
}

.Browse {
  color: #298089;
  font-weight: 600;
  font-size: 0.9rem;
}
