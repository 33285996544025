.RefreshSchemasButton {
  justify-self: flex-start;
  margin-right: auto;
  margin-left: 30px;
  border: none;
  background-color: transparent;
  font-size: 16px;
  font-weight: 500;
  background-color: rgb(240, 240, 240);
  width: 100px;
  height: 40px;
  color: rgb(150, 150, 150);
  border-radius: 5px;
  border: 1px solid rgb(217, 217, 217);
  outline: none;
  cursor: pointer;
}
